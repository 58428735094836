import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { isString } from "../../util/IsDataType";

const Spacing = styled.div`
  margin-top: ${props => props.marginTop};  
`;

const OoSpacing = ({ marginTop }) => {
  let correctedMarginTop = null;
  if (isString(marginTop)) {
    correctedMarginTop = marginTop;
  } else {
    correctedMarginTop = `${marginTop}rem`;
  }
  return (<Spacing marginTop={correctedMarginTop} />);
};

OoSpacing.propTypes = {
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
export default OoSpacing;