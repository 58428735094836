import React, { Component } from "react";
import * as PropTypes from "prop-types";


class ButtonTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: props.initialSeconds,
      timeOver: props.timeOver,
    };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if (this.state.seconds === 0) {
      this.state.timeOver();
    }
    this.setState(prevState => ({ seconds: prevState.seconds - 1 }));
  }

  render() {
    const formattedTimer = ` (${this.state.seconds})`;
    return (
      <span>{formattedTimer}</span>
    );
  }
}

ButtonTimer.propTypes = {
  initialSeconds: PropTypes.number.isRequired,
  timeOver: PropTypes.func.isRequired,
};

export default ButtonTimer;