import React from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { navigate } from "gatsby";
import styled from "styled-components";
import LoadingSpinner from "../../element/graphical/LoadingSpinner";
import theme, {
  ooTextWidth,
  ooContentWidthNarrow,
  ooVerticalSpacing,
} from "../../element/theme";
import profileVisibilityGuide from "./steam-profile-visibility-guide.gif";
import { AuthContext } from "../AuthContextProvider";
import { postSteamLogin, postSteamProfileVisibility } from "./steam-auth";
import { ROUTES } from "../../navigation/InternalRoute";
import ConciseLoginNotice from "./ConciseLoginNotice";
import OoSpacing from "../../element/page-element/OoSpacing";
import ButtonTimer from "./ButtonTimer";
import { logUnknownError } from "../../util/error-handling/sentry-integration";

const LoginWidthDiv = styled.div`
  max-width: ${ooContentWidthNarrow}rem;
  margin: ${props => props.margin};
`;
const ProfileVisibilityGuideImg = styled.img`
  margin: 0.5rem auto 3rem auto;
  width: 100%;
  max-width: ${ooTextWidth}rem;
  height: auto;
`;

const textWaiting = [
  "Logging you in...",
  "Thanks!",
];
const textNoVisibleProfile = [
  "Set your Steam profile settings to public",
  "For the Backlog Challenge to work, Oberion needs to see your Steam profile.",
  "Change your profile settings like this:",
];
const textUnknownError = [
  "An unknown error occurred. Try again later.",
];


/**
 * Guide user to set their profile to public.
 */
class ConnectSteamOnboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginState: null,
      isButtonDisabled: false,
    };
  }

  componentDidMount() {
    if (this.context.isLoggedIn() === false) {
      // TODO this will fire a network request every time someone return to this page. Is that intended?
      this.httpRequestSteamLogin();
    }
  }

  // TODO merge this function with httpRequestGameDetails
  httpRequestSteamLogin = async () => {
    const response = await postSteamLogin(this.context.getSteamid());
    // console.log("response");
    // console.log(response);

    if ((response != null) && (response.data.status === "success_OldUser" || response.data.status === "success_NewUser")) {
      this.context.logUserIn(this.context.getSteamid());
      this.context.setUserObject(response.data.userObject);
      // console.log("response.data.userObject");
      // console.log(response.data.userObject);

      // TODO case: user logged in previously with public account. Now account is private. Let user continue to BC but show a notification to set account public again.
      if (response.data.userObject.game_detail_visible === true) {
        navigate(ROUTES.BACKLOG_CHALLENGE.TO);
      }
      // else if (response.data.userObject.game_detail_visible === false) {
      else {
        this.setState({ loginState: "privateProfile" });
      }

    } else if ((response != null) && (response.data.status === "fail_steamServersDown")) {
      this.setState({ loginState: "unknownError" });
      logUnknownError("ConnectSteamOnboarding.jsx",
        "httpRequestSteamLogin #first",
        "response",
        response,
        "Unknown Error");

      // console.log("ELSE show steam server down message");
      // TODO show steam server down message
    } else {
      this.setState({ loginState: "unknownError" });
      logUnknownError("ConnectSteamOnboarding.jsx",
        "httpRequestSteamLogin #second",
        "response",
        response,
        "Unknown Error");

      // console.log("ELSE show steam server down message");
      // TODO show steam server down message
    }
  };

  handleClick = () => {

  };

  httpRequestGameDetails = async () => {
    if (this.state.isButtonDisabled) {
      return;
    }
    this.setState({ isButtonDisabled: true });

    /** --- check backend if profile is public --- */
    const response = await postSteamProfileVisibility(this.context.getSteamid());
    // console.log(response);

    /** --- error occurred --- */
    if (response == null) {
      this.setState({ loginState: "unknownError" });
      logUnknownError("ConnectSteamOnboarding.jsx",
        "httpRequestGameDetails",
        "response",
        response,
        "Unknown Error");
    }

    /** --- game detail is public --- */
    if (response.data.status === "success" && response.data.visibility === true) {
      this.context.logUserIn(this.context.getSteamid());
      this.context.setUserObject(response.data.userObject);
      navigate(ROUTES.BACKLOG_CHALLENGE.TO);
    }

    /** --- game detail is private --- */
    if (response.data.status === "success" && response.data.visibility === false) {
      this.setState({ loginState: "privateProfile" });
    }
  };

  enableButton = () => {
    this.setState({ isButtonDisabled: false });
  };

  tryAgainButton = () => (
    <Button disabled={this.state.isButtonDisabled}
            color="primary" variant="contained" size="large"
            onClick={this.httpRequestGameDetails}>
      Try Again
      {this.state.isButtonDisabled && (
<ButtonTimer initialSeconds={8}
                                                   timeOver={this.enableButton} />
      )}
    </Button>
  );
  showSpinner = () => (
    <LoginWidthDiv margin={`0 0 ${ooVerticalSpacing}rem 0`}>
      <Typography variant="h6">{textWaiting[0]}</Typography>
      <LoadingSpinner color={theme.palette.primary.dark} margin="3rem auto;" />
      {this.tryAgainButton()}
    </LoginWidthDiv>

  );
  showGifGuide = () => (
    <>
      <Typography variant="h6" gutterBottom>{textNoVisibleProfile[0]}</Typography>
      <Typography variant="body1" paragraph>{textNoVisibleProfile[1]}</Typography>
      <OoSpacing marginTop="2rem" />
      <Typography variant="subtitle1">{textNoVisibleProfile[2]}</Typography>
      <ProfileVisibilityGuideImg src={profileVisibilityGuide}
                                 alt="Guide to change your Steam Profile Visibility" />
      {this.tryAgainButton()}
      <OoSpacing marginTop="2rem" />
      <LoginWidthDiv margin={`0 0 ${ooVerticalSpacing}rem 0`}>
        <ConciseLoginNotice />
      </LoginWidthDiv>
    </>
  );

  showUnknownError = () => (
    <LoginWidthDiv margin={`0 0 ${ooVerticalSpacing}rem 0`}>
      {/*TODO [#unknown error @Özkan] implement reusable unknown error component, give user feedback, refer to twitter, reuse every time there could be empty server responses */}
      <Typography variant="h6" gutterBottom>{textUnknownError[0]}</Typography>
    </LoginWidthDiv>
  );


  showLoginState = () => {
    if (this.state.loginState === null) return this.showSpinner();
    // if (this.state.loginState === null) return this.showGifGuide();
    // if (this.state.loginState === null) return this.showUnknownError();
    if (this.state.loginState === "nowPrivateButPreviouslyPublicProfile") return this.showSpinner();
    if (this.state.loginState === "unknownError") return this.showUnknownError();
    if (this.state.loginState === "privateProfile") return this.showGifGuide();

  };


  render() {
    return (
      <>
        {this.showLoginState()}
      </>
    );
  }
}

ConnectSteamOnboarding.contextType = AuthContext;
export default ConnectSteamOnboarding;