import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import theme, { red2, green1 } from "../../element/theme";
import { InternalRoute, ROUTES } from "../../navigation/InternalRoute";


const LogoutConfirmationDiv = styled.div`
  border-top: solid 1px ${theme.palette.grey["400"]};
  border-bottom: solid 1px ${theme.palette.grey["400"]};
  margin: 0 auto 4rem auto;
  padding: 2rem 0;
  text-align: center;

  display: flex;
  flex-flow: column nowrap;
`;
const RedTypography = styled(Typography)`
  color: ${red2};
`;
const PositiveTypography = styled(Typography)`
  color: ${green1};
`;
const goHome = "Go back ";

const LogoutConfirmation = ({ accountDeleted }) => {
  let confirmationMessage = null;
  if (accountDeleted) {
    confirmationMessage = (
      <>
        <RedTypography variant="h3" paragraph>Your account has been deleted</RedTypography>
        <RedTypography variant="h3" paragraph>You are now logged out</RedTypography>
        <PositiveTypography variant="h4" paragraph>Come back any time!</PositiveTypography>
      </>
    );
  } else {
    confirmationMessage = (
      <RedTypography variant="h3" paragraph>You are logged out</RedTypography>
    );
  }

  return (
    <LogoutConfirmationDiv>
      {confirmationMessage}
      <Typography variant="body1" gutterBottom>
        {goHome}
        <InternalRoute caption={ROUTES.HOME.CAPTION} to={ROUTES.HOME.TO} lightTheme />
      </Typography>
      <Typography variant="caption" gutterBottom>or</Typography>
      <Typography variant="body1" gutterBottom>Log in again below</Typography>
    </LogoutConfirmationDiv>
  );
};
LogoutConfirmation.defaultProps = {
  accountDeleted: false,
};
LogoutConfirmation.propTypes = {
  accountDeleted: PropTypes.bool,
};

export default LogoutConfirmation;