import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { ConciseList } from "../../element/page-element/ConciseList";
import { InternalRoute, ROUTES, ThemedLink } from "../../navigation/InternalRoute";

const LoginNoticeDiv = styled.div`
  width: 100%;
  text-align: left;
  margin: 0 auto;
`;

const alwaysDelete = ["You can ", "completely remove and delete your data", " at any time."];
const neverSold = "Your personal data will never be sold or transferred to anyone.";
const fetchSteamData1 = "Public information such as steamid, profile name, avatar, and your game list is fetched from the Steam servers.";
const privacyPolicyLink = ["Inspect Oberion's ", "privacy practices here"];
const steamDisclaimer = ["This website is not affiliated with Steam or Valve Corporation."];

const ConciseLoginNotice = () => {
  return (
    <LoginNoticeDiv>
      <Typography component="span">
        <ConciseList>
          <li>
            {alwaysDelete[0]}
            {alwaysDelete[1]}
            {alwaysDelete[2]}
          </li>

          <li>{fetchSteamData1}</li>
          <li>{neverSold}</li>
          <li>{steamDisclaimer}</li>
          <li>
            {privacyPolicyLink[0]}
            <InternalRoute caption={privacyPolicyLink[1]} to={ROUTES.PRIVACY.TO} lightTheme />
            &#46;
          </li>
        </ConciseList>
      </Typography>
    </LoginNoticeDiv>
  );
};

export default ConciseLoginNotice;