import React, { useContext } from "react";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import styled from "styled-components";
import { navigate } from "gatsby";
import ConnectSteam from "../components/authentication/steam/ConnectSteam";
import Layout from "../components/element/layout/Layout";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import theme, {
  grey8,
  ooVerticalSpacing,
  ooContentWidthNarrow,
} from "../components/element/theme";
import SEO from "../components/element/SEO";
import LogoutConfirmation from "../components/authentication/steam/LogoutConfirmation";
import ConnectSteamHeader from "../components/authentication/steam/ConnectSteamHeader";
import ConnectSteamOnboarding from "../components/authentication/steam/ConnectSteamOnboarding";
import { ROUTES } from "../components/navigation/InternalRoute";
import { AuthContext } from "../components/authentication/AuthContextProvider";

const LoginWidthDiv = styled.div`
  max-width: ${ooContentWidthNarrow}rem;
  margin: ${props => props.margin};
`;
const seo = {
  title: "Steam Login - Oberion",
  description: "Log into Oberion via your Steam account. Explore your gaming library, get stats, and insights into your gaming cosmos.",
};

// TODO redirect user if logged in for this whole page
const SteamLogin = ({ location }) => {
  const authContext = useContext(AuthContext);
  let logoutConfirmation = null;
  let displayComponent = null;

  /** --- #loggedOut --- */
  // undefined props crash gatsby build page generation. Check if props object exist first.
  if (location.state) {
    if (location.state.accountDeleted === true) {
      logoutConfirmation = (<LogoutConfirmation accountDeleted />);
    } else if (location.state.loggedOut === true) {
      logoutConfirmation = (<LogoutConfirmation />);
    }
  }

  /** --- #welcome --- */
  else if (location.search.length > 0) {
    const parsedResponse = queryString.parse(location.search);
    if ({}.hasOwnProperty.call(parsedResponse, "openid.identity")) {
      if (authContext.getSteamid() === null) {
        const steamid = parsedResponse["openid.identity"].match(/\d+$/)[0];
        authContext.setSteamid(steamid);
        /* ---
           Disable navigate for nicer development experience, enables browser "go back"
           Expect dismissible "Warning: Cannot update during an existing state transition"
           Cause: "go back" catches React during state transition
           which with the enabled navigate will not be triggered
        --- */
        // navigate(ROUTES.STEAM_LOGIN.WELCOME, { replace: true });
      }
    }
  }

  // noinspection JSObjectNullOrUndefined
  if (authContext.getSteamid() !== null) {
    displayComponent = (
      <ConnectSteamOnboarding />
    );
  } else {
    displayComponent = (
      <LoginWidthDiv margin={`0 0 ${ooVerticalSpacing}rem 0`}>
        <ConnectSteam />
      </LoginWidthDiv>
    );
  }

  return (
    <Layout hideNewsletter>
      <SEO {...seo} />
      <LayoutSetBackground alignItems="center" justifyContent="center"
                           backgroundColor={grey8} color={theme.palette.secondary.contrastText}>

        <LoginWidthDiv margin={`${ooVerticalSpacing}rem 0 0 0`}>
          {logoutConfirmation}
          <ConnectSteamHeader />
        </LoginWidthDiv>
        {displayComponent}

      </LayoutSetBackground>
    </Layout>
  );
};
SteamLogin.defaultProps = {
  location: undefined,
};
SteamLogin.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      loggedOut: PropTypes.bool,
      accountDeleted: PropTypes.bool,
    }),
    search: PropTypes.string.isRequired,
  }),
};
export default SteamLogin;
