import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { InternalRoute, ROUTES } from "../../navigation/InternalRoute";
import iconSteam from "../../../res/branded/store-icon/steam.min.svg";
import { getOrigin } from "./steam-auth";
import ConciseLoginNotice from "./ConciseLoginNotice";


const explainer = [
  "Logging in via Steam enables your personal gaming library on Oberion",
  "Take a look at the ",
  "preview",
  " to see what's coming"];


const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: "0 auto",
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
    padding: "1rem 3rem",
    textTransform: "none",
    margin: "0 auto",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const loginForm = (
  <form action="https://steamcommunity.com/openid/login" method="post"
        // className={classes.buttonContainer}
  >
    <input type="hidden" name="openid.identity"
           value="http://specs.openid.net/auth/2.0/identifier_select" />
    <input type="hidden" name="openid.claimed_id"
           value="http://specs.openid.net/auth/2.0/identifier_select" />
    <input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
    <input type="hidden" name="openid.mode" value="checkid_setup" />
    <input type="hidden" name="openid.realm" value={getOrigin("openIdRealm")} />
    <input type="hidden" name="openid.return_to" value={getOrigin("openIdReturnTo")} />
    <Button variant="contained" color="primary" size="large"
            // className={classes.button}
            type="submit">
      Log in through Steam
      <img src={iconSteam} alt="Steam Store"
           // className={classes.rightIcon}
      />
    </Button>
  </form>
);

const ConnectSteam = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {explainer[0]}
      </Typography>
      <Typography variant="subtitle1">
        {explainer[1]}
        <InternalRoute to={ROUTES.LIBRARY.TO} caption={explainer[2]} lightTheme />
        {explainer[3]}
      </Typography>

      {/*{loginForm}*/}
      <Button variant="contained" color="primary" size="large" className={classes.button}
              type="submit">
        Log in through Steam
        <img src={iconSteam} alt="Steam Store" className={classes.rightIcon} />
      </Button>
      <ConciseLoginNotice />
    </>
  );
};
export default ConnectSteam;