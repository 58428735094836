import axios from "axios";
import * as Sentry from "@sentry/browser";

/**
 * Sentry logging example.
 * Use this as a template for logging errors throughout Teleskop.
 *
 * Sentry.setExtra: Set extra information as "key, value" pairs.
 * @see https://docs.sentry.io/platforms/javascript/#extra-context
 *
 * Sentry.captureException: Capture, log, and send exception to Sentry.
 * @see https://docs.sentry.io/platforms/javascript/#capturing-errors
 *
 * Interesting Sentry features that are NOT implemented:
 * Add context to errors: @see https://docs.sentry.io/enriching-error-data/context/?platform=browser
 * Supply source maps: @see https://docs.sentry.io/platforms/javascript/sourcemaps/
 * Setting local scope: @see https://docs.sentry.io/enriching-error-data/scopes/?platform=javascript
 */


const BROKEN_ENDPOINT = "http://localhost:3001/api/broken-endpoint";
const axiosConfig = { headers: { "Content-Type": "text/plain" } };

export const logHttpError = async (payload) => {
  try {
    return await axios.post(BROKEN_ENDPOINT, payload, axiosConfig);
  } catch (err) {
    Sentry.setExtra("file", "file.js");
    Sentry.setExtra("function", "functionToPost");
    Sentry.setExtra("parameters", [logHttpError]);
    Sentry.captureException(new Error(err));
    return null;
  }
};

export const logUnknownError = (file, functionName, moreInfoDescription, moreInfoObject, errorMessage) => {
  Sentry.setExtra("file", file);
  Sentry.setExtra("function", functionName);
  Sentry.setExtra(moreInfoDescription, moreInfoObject);
  Sentry.captureException(new Error(errorMessage));
};
