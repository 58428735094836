import React from "react";
import iconOberion from "../../../res/branded/oberion/oberion-brand-icon-white-OberionIO.min.svg";
import { Typography } from "@material-ui/core";
import iconSteam from "../../../res/branded/store-icon/steam.min.svg";
import styled from "styled-components";
import ooTheme from "../../element/theme";
import { lessOrEqualThan } from "../../util/mediaQuery";

const OberionPlusSteam = styled.div`
  border-radius: 2.5rem;
  color: #fff;
  background: ${ooTheme.palette.background.paper};
  width: 100%;
  margin: 0 auto 3rem auto;
  padding: 3rem 1rem;
    ${lessOrEqualThan.sm`
    padding: 3rem 0rem;
  `};
  
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BrandLogo = styled.img`
  margin: 0 2rem;
  height: 5rem;
  ${lessOrEqualThan.sm`
    margin: 0 1rem;
    height: 4rem;
  `};
`;

const explainer = [
  "Logging in via Steam enables your personal gaming library on Oberion",
];

const ConnectSteamHeader = () => {
  return (
    <>
      <OberionPlusSteam>
        <BrandLogo src={iconOberion} alt="Oberion Logo" />
        <Typography component="span" variant="h1">+&nbsp;</Typography>
        <BrandLogo src={iconSteam} alt="Steam Logo" />
      </OberionPlusSteam>
    </>
  );
};

export default ConnectSteamHeader;